<template>
    <v-card light height="100%">
        <v-card-text>
            <v-container>
                <v-row class="pl-15 pr-15">
                    <v-col cols="12" md="12" class="gridSummaryCol12" v-if="loaded">
                        Summary
                        <vc-donut background="white"  :size="200" unit="px" :thickness="30" has-legend
                            legend-placement="top" :sections="sections" :total="100" :start-angle="0"
                            :auto-adjust-text-size="true" >
                            <h3>Total devices {{ total }}</h3>
                        </vc-donut>
                    </v-col>

                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "generic_view",
    data() {
        return {
            total: 0,
            active: 0,
            inactive: 0,
            loaded: false,
            sections: []
        }
    },
    mounted() {
        this.getSummary()
    },
    methods: {
        getSummary() {
            let url = this.api_url + "summary"
            this.loaded = false;
            this.axios.get(url, { headers: { Authorization: this.$session.get('token') } }).then(response => {
                this.total = response.data.total;
                this.active = response.data.active;
                this.inactive = response.data.inactive;
                this.sections = [
          { label: 'Total active ('+this.active+')', value: this.active*100/this.total, color: 'green' },
          { label: 'Total inactive ('+this.inactive+')', value: this.inactive*100/this.total, color: 'lightgray' }]
                this.loaded = true
            })
        },
        getCurrentDate() {
            let date = new Date();
            let month = date.getMonth();
            let year = date.getFullYear();
            let months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];

            return `${months[month]} ${year}`;
        }
    }
}
</script>
<style>
.gridSummaryCol6l {
    border: 1px solid darkgray;
    padding: 1em;
    margin: 1em 0em 0 0;
}

.gridSummaryCol6r {
    border: 1px solid darkgray;
    padding: 1em;
    margin: 1em 0 0 0em;
}

.gridSummaryCol12 {
    border: 1px solid darkgray;
    padding: 1em;
    padding-bottom: 5em;
    margin-top: 1em;
}
</style>