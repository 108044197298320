import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import VueSession from "vue-session/index.esm";
import VueGlobalVariable from "vue-global-var";
import VueCryptojs from 'vue-cryptojs'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
import './ml'
Vue.use(VueCryptojs);

Vue.use(VueAxios, axios);
Vue.use(VueSession)
Vue.use(VModal)
Vue.config.productionTip = false

Vue.use(VueGlobalVariable, {
  globals: {
    version: "1.0",
    api_url: "https://panel.obdmotion.com/api/client/",
    light_blue: "#0fe8ef",
    dark_blue: "#1065c2"
  }
});
Vue.use(Donut);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
