<template>
  <v-app id="inspire">
    <v-navigation-drawer v-if="logged && rol < 3" :permanent="$vuetify.breakpoint.mdAndUp" v-model="drawer"
      :clipped="true" app light style="background: white">
      <v-list dense>
        <v-list-item-group :color="dark_blue">
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-on:click="logout">
            <v-list-item-action>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="logged" app fixed height="50"  :class="'appbarHead '+(rol===3)?'installerHead':''" clipped-left>
      <v-app-bar-nav-icon style="color: black" @click.stop="drawer = !drawer" />
      <v-toolbar-title class="title">
        <img src="./assets/obdmotion_logo.jpg" style="width: 150px">
      </v-toolbar-title>
      <v-spacer />
      <h4 class="welcomeUser">Welcome <span>{{ getName() }} </span>
      </h4>
      <v-icon v-on:click="logout" v-if="rol===3" class="ml-3" color="grey">mdi-exit-to-app</v-icon>

    </v-app-bar>
    <v-main style="background: white;">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  created() {
    this.$vuetify.theme.dark = true;
    if (this.$session.exists()) {
      switch (this.$session.get('rol')) {
        case 1:
          this.items = [
            { icon: 'mdi-home', text: 'Home', route: '/' },
            { icon: 'mdi-car-connected', text: 'Devices', route: '/devices' },
            { icon: 'mdi-credit-card-outline', text: 'Payments', route: '/payments' },
            { icon: 'mdi-alert', text: 'Reports', route: '/report' },
            { icon: 'mdi-account', text: 'Users', route: '/users' },
            { icon: 'mdi-car-cog', text: 'VIN DB', route: '/vin' },
            { icon: 'mdi-cellphone-arrow-down', text: 'Update', route: '/updates' },
          ];
          break;
        case 2:
          this.items = [
            { icon: 'mdi-home', text: 'Home', route: '/' },
            { icon: 'mdi-car-connected', text: 'Devices', route: '/devices' },
            { icon: 'mdi-credit-card-outline', text: 'Payments', route: '/payments' },
            { icon: 'mdi-account', text: 'User info', route: '/users' },
            { icon: 'mdi-cellphone-arrow-down', text: 'Update', route: '/updates' }
          ]
          break;
        case 3:
          this.items = []
          break;
      }
      this.rol = this.$session.get('rol')
      this.logged = true;
    } else
      this.$router.push('/login');
  },

  data: () => ({
    drawer: null,
    logged: false,
    rol: null,
    isOpen: true,
    items: [],
  }),
  methods: {
    getName() {
      return this.$session.get('name')
    },
    logout() {
      this.$session.destroy();
      this.$router.push('/login');
      location.reload();
    }
  }
};
</script>
<style>
.title {
  display: contents;
}

.title h1 {
  font-size: 16pt;
  color: light_blue;
}

.title img {
  width: 15%;
}

.appbarHead {
  background: white !important;
  margin-bottom: 70px !important;
}
.installerHead{
  background: white !important;
  height:55px !important;
}
.welcomeUser {
  color: black;
  font-weight: 300;
}

.welcomeUser span{
  color: black !important;
  text-decoration: none;
  font-weight: 600;
}

.welcomeUser a:hover {
  color: #0fe8ef !important;
}
</style>