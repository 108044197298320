import Vue from 'vue'
import { MLanguage, MLCreate, MLInstaller } from 'vue-multilanguage'

Vue.use(MLInstaller)

export default new MLCreate({
  initial: 'Esp',
  save: process.env.NODE_ENV === 'production',
  languages: [
    new MLanguage('Esp').create({
      news: 'NOTICIAS',
      contact: 'CONTACTO',
      covid: 'COVID-19',
      publicTransport: 'TRANSPORTE PÚBLICO',
    })]
})
