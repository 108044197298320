<template>
    <v-card light class="elevation-0">
        <v-card-text style="padding:8px 15px;">
            <v-container style="max-width:100%">
                <v-row class="mt-2">
                    <v-col cols="12" class="ma-0 pa-0"
                        style="display:flex; margin-top:-10px !important;margin-bottom:10px !important">
                        <v-text-field v-model="SN" dense outlined label="Search device serial number" width="90%" />
                        <v-spacer></v-spacer>
                        <v-btn class="ml-1" style="height:37px;" width="10%" color="primary" :loading="loading"
                            @click="searchDev(true)" :disabled="SN.length == 0">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <!--<v-btn class="ml-1" style="height:37px;" width="10%" color="primary" :loading="loading"
                            @click="scanQR = true">
                            <v-icon>mdi-camera</v-icon>
                        </v-btn>-->
                    </v-col>
                    <v-col cols="12" md="12" v-if="scanQR">
                        <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
                    </v-col>
                    <v-col cols="12" md="12" v-if="error" class="ma-0 pa-0">
                        <v-alert dense outlined type="error">
                            You are not authorized for search this device
                        </v-alert>
                    </v-col>
                    <v-col cols="12" md="12" class="ma-0 pa-0" v-if="searched" style="display:flex;">
                        <v-alert width="48%" dense outlined color="red" v-if="link > 0" style="text-align:center">
                            Not connected
                        </v-alert>
                        <v-alert width="48%" dense outlined color="green" v-else style="text-align:center">
                            Connected
                        </v-alert>
                        <v-spacer></v-spacer>
                        <v-alert width="48%" dense outlined color="red" v-if="veh_mode != 0" style="text-align:center">
                            Calibrating
                        </v-alert>
                        <v-alert width="48%" dense outlined color="green" v-else style="text-align:center">
                            Calibrated
                        </v-alert>
                    </v-col>

                    <v-col cols="12" md="12" v-if="searched" class="ma-0 pa-0">
                        <v-row>
                            <v-col cols="6" md="6" v-for="data in serverData" v-bind:key="data.title">
                                <h2 class="right "><span>{{ data.title }}</span></h2>
                                <h4 v-for="item in data.data" v-bind:key="item.key">
                                    <span class="spanTitle">{{ item.key }}</span>
                                    <span class="value" v-bind:class="getClass(data.title, item.key, item.value)">{{
                                        getKey(data.title, item.key, item.value)
                                    }}</span>
                                </h4>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions v-if="searched" :class="(obd_volt == null || veh_mode != 0) ? 'hasWarning' : ''"
            style="padding:0 12px;max-width:100%;width:100%">
            <v-container style="max-width:100%">
                <v-row>
                    <v-col cols="11" md="11" v-if="searched" class="ma-0 pa-0">
                        <v-alert dense outlined color="red" v-if="obd_volt == null"
                            style="text-align:start;font-size: 11pt;font-weight: 500;">
                            Please keep the vehicle stopped and completely off for a correct OBD Volt detection
                        </v-alert>
                        <v-alert dense outlined color="red" v-if="obd_volt != null && veh_mode != 0"
                            style="text-align:start;font-size: 11pt;font-weight: 500;">
                            Please start the engine or turn on ignition and wait some minutes
                        </v-alert>
                    </v-col>
                    <v-col cols="11" md="11" class="ma-0 pa-0">
                        <h2 class="right actions mt-2"><span>Actions</span></h2>
                    </v-col>
                    <v-col cols="11" md="11" class="ml-0 mr-0 mt-5 pa-0" style="display:flex">
                        <v-btn :disabled="link > 0 || resetON || recabTXT == 'ERROR' || resetTXT == 'ERROR'"
                            :loading="calibON" width="48%" @click="sendDirectTask('veh_mode', 1)">
                            {{ recabTXT }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="link > 0 || calibON || recabTXT == 'ERROR' || resetTXT == 'ERROR'"
                            :loading="resetON" width="48%" @click="sendDirectTask('dev_mode', 3)">
                            {{ resetTXT }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
    components: { QrcodeStream },
    name: "installer_view",
    data() {
        return {
            scanQR: false,
            qerror: '',
            qdecode: '',
            SN: '',
            info: [],
            bussy: false,
            error: false,
            link: 0,
            veh_mode: 0,
            obd_volt: 0,
            calibON: false,
            resetON: false,
            searched: false,
            loading: false,
            refreshTimeout: null,
            recabTXT: 'Recalibrate',
            resetTXT: 'Reset'
        }
    },

    watch: {
        SN(nval){
            console.log(nval)
            this.SN = nval.toUpperCase()
        },
        info(newinfo) {
            this.link = newinfo.dev_link;
            this.veh_mode = newinfo.veh_mode;
            this.obd_volt = newinfo.obd_volt;
            this.serverData = [{ title: 'Device', data: [{ key: 'Stat', value: newinfo.dev_stat }, { key: 'HW', value: newinfo.dev_hw }, { key: 'FW', value: newinfo.dev_fw }] },
            { title: 'Network', data: [{ key: 'Stat', value: newinfo.net_stat }] },
            { title: 'OBD', data: [{ key: 'Stat', value: newinfo.obd_stat }, { key: 'Volt', value: this.getOBDVolt(newinfo.obd_volt) }, { key: 'CAN1', value: this.getCan(newinfo.can_ch0) }, { key: 'CAN2', value: this.getCan(newinfo.can_ch1) }] },
            { title: 'GPS', data: [{ key: 'Stat', value: newinfo.gps_stat }] }]
        }
    },
    methods: {
        onDecode(decodedString) {
            this.SN = decodedString;
            this.searchDev(true);
            this.scanQR = false;
        },
        async onInit(promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.qerror = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    this.qerror = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.qerror = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.qerror = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.qerror = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.qerror = "ERROR: Stream API is not supported in this browser"
                }
            }
        },
        getCan(ocan) {
            return ocan.length > 1 ? 'detected' : 'Not detected'
        },
        getOBDVolt(ovolt) {
            switch (ovolt) {
                case 0:
                    return '12V'
                case 1:
                    return '14V'
                case 2:
                    return '24V'
            }
        },
        searchDev(lding) {
            this.refreshing = true;
            this.error = false;
            this.SN = this.SN.replace(/\s/, '')
            if (lding) {
                this.loading = true;
                this.axios.get(this.api_url + "searchdevice/" + this.SN, { headers: { Authorization: this.$session.get('token') } }).then(response => {
                    this.$session.set('devid', response.data.current.dev_key_pub)
                    let url = this.api_url + "devicedata/" + this.$session.get('devid')
                    this.axios.get(url, { headers: { Authorization: this.$session.get('token') } }).then(response => {
                        this.searched = false;
                        this.info = response.data.current;
                        this.loaded = false;
                        this.searched = true;
                        this.refreshing = false;
                        this.loading = false
                        let self = this
                        this.refreshTimeout = setTimeout(function () { self.searchDev(false) }, 5000)
                    })
                }).catch(() => {
                    this.loading = false;
                    this.error = true;
                    this.searched = false;
                    clearTimeout(this.refreshTimeout)
                })
            } else {
                let url = this.api_url + "devicedata/" + this.$session.get('devid')
                this.axios.get(url, { headers: { Authorization: this.$session.get('token') } }).then(response => {
                    this.searched = false;
                    this.info = response.data.current;
                    this.loaded = false;
                    this.searched = true;
                    this.refreshing = false;
                    this.loading = false
                    let self = this
                    this.refreshTimeout = setTimeout(function () { self.searchDev(false) }, 5000)
                })
            }
        },

        sendDirectTask(key, mode) {
            if (key == "veh_mode") this.calibON = true;
            if (key == "dev_mode") this.resetON = true;
            this.bussy = true;
            this.axios.post(this.api_url + 'pendingconfig', { devid: this.$session.get('devid'), msg_new: JSON.parse('{"' + key + '":' + mode + '}'), msg_save: 0 }, { headers: { Authorization: this.$session.get('token') } }).then(() => {
                this.bussy = false;
                if (key == "veh_mode") {
                    this.recabTXT = 'OK'
                    this.calibON = false;
                }
                if (key == "dev_mode") {
                    this.resetTXT = 'OK'
                    this.resetON = false;
                }
                let self = this;
                setTimeout(function () {
                    self.error = false;
                    self.recabTXT = 'Recalibrate'
                    self.resetTXT = 'Reset'
                    self.calibON = false;
                    self.resetON = false;
                }, 2000)
                this.searchDev(false);
            }).catch(() => {
                this.bussy = false;
                if (key == "veh_mode") {
                    this.recabTXT = 'ERROR'
                    this.calibON = false;
                }
                if (key == "dev_mode") {
                    this.resetTXT = 'ERROR'
                    this.resetON = false;
                }
                let self = this;
                setTimeout(function () {
                    self.error = false;
                    self.recabTXT = 'Recalibrate'
                    self.resetTXT = 'Reset'
                    self.calibON = false;
                    self.resetON = false;
                }, 2000)
            })
        },
        getKey(title, key, value) {
            if (key == 'Volt' && value == null) return 'Not detected'
            if (key == 'Level') return value + '%'
            if (key == 'Stat') {
                switch (title) {
                    case 'Device':
                        if (this.link == 2 || value == null) return 'Unkown'
                        else {
                            if (value == 0) return 'Sleep'
                            if (value == 1) return 'Wake'
                        }

                        break;
                    case 'Network':
                        if (this.link == 2 || value == null) return "Unknown"
                        if (value == 0) return "Not registered";
                        if (value == 1) return "Registered";
                        if (value == 2) return "4G";
                        break;
                    case 'Host':
                        if (value == 0) return "Not connected";
                        if (value == 1) return "Connected";
                        break;
                    case 'OBD':
                        if (this.link == 2 || value == null) return "Unknown"
                        if (value == 0) return "Unplugged";
                        if (value == 1) return "Unpowered";
                        if (value == 2) return "Powered";
                        break;
                    case 'GPS':
                        if (this.link == 2 || value == null) return "Unknown"
                        if (value == 0) return "Not fixed";
                        if (value == 1) return "Fixed";
                        break;
                    case 'Battery':
                        if (value == 0) return "OK";
                        if (value == 1) return "Faulty";
                        break;
                }
            } else return value;
        },
        getClass(title, key, val) {
            if (title == "Device" && key == "Stat") return (this.link === 2 || val == null) ? 'errorClass' : 'stat'
            switch (title) {
                case 'Network':
                    if (key === "Stat")
                        return (this.link === 2 || val == null) ? 'errorClass' : val === 0 ? 'errorClass' : 'stat'
                    break;
                case 'Host':
                    if (key === "Stat")
                        return (val != 0 || val == null) ? 'stat' : 'errorClass';
                    break;
                case 'OBD':
                    if (key === "Stat") {
                        if (this.link == 2 || val == null) return 'errorClass'
                        return (val == 2) ? 'stat' : val == 1 ? 'goldclass' : 'errorClass';
                    }
                    break;
                case 'GPS':
                    if (key === "Stat")
                        return (val != 0 && val != null) ? 'stat' : 'errorClass';
                    break;
                case 'Battery':
                    if (key === "Stat")
                        return (val == 0) ? 'stat' : 'errorClass';

                    break;
                default: return '';
            }
        }
    }
}
</script>
<style>
.spanTitle {
    display: inline-flex;
    width: 45px;
}

.value {
    font-weight: 300;
}

.stat {
    color: lightgreen;
    font-weight: 500;
}

.goldclass {
    color: gold;
    font-weight: 500;
}

.errorClass {
    color: lightcoral;
    font-weight: 500;
}

.hasWarning {
    height: 186px;
}

.v-app-bar__nav-icon.v-btn.v-btn--icon.v-btn--round.theme--dark.v-size--default {
    display: none;
}
</style>