import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Dashboard,
  },
  {
    path: "/devices",
    name: "devices",
    component: () =>
      import("../views/Devices.vue"),
  },
  {
    path: "/device/:deviceid",
    name: "device",
    component: () =>
      import("../views/Device.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import("../views/LoginView.vue"),
  },
  {
    path: "/payments",
    name: "payments",
    component: () =>
      import("../views/Payments.vue"),
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import("../views/Users.vue"),
  },
  {
    path: "/user/:uid",
    name: "user",
    component: () =>
      import("../views/User.vue"),
  },
  {
    path: "/updates",
    name: "updates",
    component: () =>
      import("../views/Updates.vue"),
  },
  {
    path: "/vin",
    name: "updates",
    component: () =>
      import("../views/VIN.vue"),
  },
  {
    path: "/globalconfig",
    name: "globalconfig",
    component: () =>
      import("../views/GlobalConfig.vue"),
  },
  {
    path: "/screenerror",
    name: "screenerror",
    component: () =>
      import("../views/Screenerror.vue"),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
