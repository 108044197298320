<template style="background: white">
    <generic_view v-if="getRol() < 3"/>
    <installer_view v-else-if="getRol() === 3"/>
</template>
<script>
import generic_view from '@/components/dashboard.vue/generic_view.vue';
import installer_view from '@/components/dashboard.vue/installer_view.vue';
export default {
    name: "DashboardView",
    components:{generic_view,installer_view},
    methods: {
        getRol(){
            return this.$session.get('rol')
        }
    }
}
</script>
<style>
.gridSummaryCol6l {
    border: 1px solid darkgray;
    padding: 1em;
    margin: 1em 0em 0 0;
}

.gridSummaryCol6r {
    border: 1px solid darkgray;
    padding: 1em;
    margin: 1em 0 0 0em;
}

.gridSummaryCol12 {
    border: 1px solid darkgray;
    padding: 1em;
    padding-bottom: 5em;
    margin-top: 1em;
}
</style>